import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day of Test Week`}</em></p>
    <p>{`Max Height Box Jumps 1-1-1-1-1 to 1Rm`}</p>
    <p>{`Deadlifts 1-1-1-1-1 to 1Rm`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Open WOD 11.2`}</strong></p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`9-Deadlifts (155/100)`}</p>
    <p>{`12-Pushups`}</p>
    <p>{`15-Box Jumps (24/20″)`}</p>
    <p><em parentName="p">{`*`}{`Bonus Test`}{`*`}{` `}</em></p>
    <p>{`1 Mile Run for Time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      